.services{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-cards {
    display: flex;
    justify-content: center;
    gap: 20px; /* Spacing between cards */
    padding: 0 10%;
}

.card {
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 16rem;
    max-width: 300px; /* Fixed width for each card */
}

.card h2 {
    color: var(--color-medium); /* Blue color for headings */
    margin-bottom: 15px;
}

.card p {
    color: #555;
    line-height: 1.6;
}

.quote{
    font-size: 1em;
    margin-top: 30px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--color-medium);
    border-radius: 4px;
    text-transform: uppercase;
    transition: background-color 0.3s;
}

.quote:hover{
    background-color: #5e1303;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .wrapper-cards {
        flex-direction: column;
        align-items: center;
        padding: 0 5%;
    }

    .card {
        max-width: 100%;
        margin-bottom: 20px;
    }
}