

.contact-us {
    padding: 40px;
    font-family: 'Roboto', sans-serif;
    width: 70%;
    margin-inline: auto;
}

.contact-us h1 { /* Dark text color for contrast */
    margin-bottom: 20px;
}

.wrap-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.wrap-form label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #555;
}

.wrap-form input[type="text"],
.wrap-form input[type="email"],
.wrap-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.wrap-form textarea {
    height: 100px;
    resize: vertical;
}

.wrap-form button {
    background-color: var(--color-medium); 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.wrap-form button:hover {
    background-color: #5a0c03; /* Darker blue on hover */
}

/* Responsive design */
@media (max-width: 840px) {
    .wrap-form {
        padding: 10px;
        width: 100%;
    }
    .contact-us{
        width: 80%;
        margin-inline: auto;
        padding-inline: 7px;
        padding-block: 40px;
        padding-left: 0px;
    }
}
