#gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;
}

.photo-switcher-container {
    text-align: center;
    margin: 20px auto;
    width: 100%;
    max-width: 700px;
    height: 500px; /* Keep the fixed height */
    position: relative; /* Needed for absolute child positioning */
    overflow: hidden;
}

.photo-switcher-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    opacity: 0; /* Start images as invisible */
}

.photo-switcher-img.active {
    opacity: 1; /* Make the active image fully visible */
    z-index: 10; /* Ensure it's on top of other images */
}


.photo-switcher-navigation {
    margin-top: 10px; /* Spacing above navigation buttons */
    position: relative; /* Ensure z-index is respected by providing position context */
    z-index: 20; /* Higher than .photo-switcher-img to stay on top */
    display: flex;
    justify-content: space-around;
    top: 435px;
}

.photo-switcher-button {
    padding: 10px 20px; /* Padding for buttons */
    margin: 0 5px; /* Space between buttons */
    border: none; /* Remove border */
    background-color: var(--color-dark); /* Button color */
    opacity: 0.7;
    color: white; /* Text color */
    border-radius: 5px; /* Rounded corners for buttons */
    cursor: pointer; /* Cursor to pointer */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s; /* Transition for hover effect */
}

.photo-switcher-button:hover {
    background-color: #221d1d; /* Darker shade on hover */
}

.photo-switcher-container:hover .photo-switcher-img {
    transform: scale(1.03); /* Scales the image up to 110% of its original size */
}

@media (max-width: 768px) { /* Target mobile devices */
    #gallery {
        margin-top: 2rem; /* Further reduce top margin */
    }

    .photo-switcher-container{
        width: 300px;
        height: 200px;
    }
    .photo-switcher-navigation {
        padding: 0 10px; /* Add padding to prevent buttons from touching the edges */
        top: 150px;
    }

    .photo-switcher-img{
        height: 200px;
    }
    .photo-switcher-button {
        padding: 5px 10px; /* Smaller buttons */
        margin: 0 2px; /* Reduce space between buttons */
    }
}
