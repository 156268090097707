.footer {
    background-color: #1a1a1a;
    color: #e0e0e0;
    padding: 60px 0;
    font-size: 1rem;
    line-height: 1.6;
    overflow: hidden; /* Prevent overflow */
}

.footer-container-global {
    width: calc(100% - 30px); /* Prevents overflow caused by padding */
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
}

.footer-container-main {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;
}

.footer-section {
    margin: 0 10px; /* Adjusted for spacing consistency */
    flex: 1;
    min-width: 160px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.footer-section h5 {
    color: #c4bfbf;
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.footer-section p, .footer-section a {
    color: #e0e0e0;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
}

.footer-section a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.footer-container-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #444;
    margin-top: 40px;
}

/* Clearfix for floated elements */
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

@media (max-width: 768px) {
    .footer-container-main {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin: 10px 0;
    }
}

