@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


:root {
    --color-light: white;
    --color-mild: #f3f4f6;
    --color-dark: #000;
    --color-medium: #c5131a;
}


html {
    scroll-behavior: smooth;
}

/* General styling */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--color-mild);
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1 {
    font-size: 2rem;
    font-weight: bolder;
}

header {
    background-color: var(--color-light);
    color: var(--color-dark);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Vertically center items */
    padding: 25px;
    max-width: 1200px;
    margin: auto;
}

/* Logo styling */
header img {
    height: 50px;
}

.nav-bar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    text-transform: uppercase;

}

.nav-bar li {
    margin-left: 20px;
}

.nav-bar li a {
    color: var(--color-dark);
    text-decoration: none;
    font-weight: 400;
    padding: 5px 10px;
}

.nav-bar li a:hover {
    color: darkgrey;
    border-radius: 5px;
}

.wrapper {
    text-align: center;
    border-radius: 10px;
    /* Rounded corners for the wrapper */
}

.wrapper.coal {
    display: flex;
    justify-content: center;
    /* Center children horizontally */
    align-items: center;
    /* Center children vertically */
    background-image: url("/public/images/Coal_burner.jpg");
    background-color: #cccccc;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 85%;
    /* Full width for smaller screens */
    margin-inline: auto;
    padding: 25px;
}

.floater {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 5px;
    max-width: 90%;
    /* Max width to prevent it from becoming too wide on large screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    /* Adapted for better mobile readability */
}

.about-us {
    background-color: var(--color-light);
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    border-radius: 8px;
}

.about-us h1 {
    text-align: center;
    margin-bottom: 20px;
}

.wrapper.about-us p {
    line-height: 1.6;
    margin-bottom: 20px;
}

.whyus {
    background-color: #eaeaea;
    padding: 15px;
    border-radius: 5px;
}

.whyus h2 {
    margin-bottom: 10px;
}

.whyus p {
    font-size: 0.9em;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 768px) {

    /* Hide the nav-bar initially */
    .nav-bar {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: -100%;
        /* Start off-screen */
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        /* Semi-transparent background */
        z-index: 1000;
        /* High z-index to be on top */
        overflow-y: auto;
        /* Allows scrolling if content is taller than the screen */
        transition: transform 0.3s ease-in-out;
        padding-top: 3rem;
        /* Space for the close button */
    }

    /* Display block will show the menu, and the transform will slide it into view */
    .nav-visible .nav-bar {
        display: block;
        transform: translateX(100%);
    }

    /* Styling for each navigation link */
    .nav-bar li a {
        color: white;
        display: block;
        padding: 1rem;
        border-bottom: 1px solid #eee;
        /* Separates items */
        font-size: 1rem;
        text-align: center;
        /* Center-align text */
        transition: background-color 0.2s;
        /* Smooth background transition for hover/focus */
    }

    /* Hover and focus styles for navigation links */
    .nav-bar li a:hover,
    .nav-bar li a:focus {
        outline: none;
        background-color: var(--color-dark);
        /* Highlight color for active/focused links */
        color: var(--color-light);
        /* Dark text color for contrast */
    }

    /* Hamburger menu - only displayed on small screens */
    .hamburger-menu {
        display: block;
        cursor: pointer;
        /* Indicates the element is interactive */
        z-index: 1001;
        /* Above the nav-bar */
        position: fixed;
        top: 1rem;
        /* Spacing from the top */
        right: 1rem;
        /* Spacing from the right */
        color: var(--color-medium);
    }



    /* Show close icon when navigation is visible */
    .nav-visible .hamburger-menu .close-icon {
        display: block;
    }

    /* Hide hamburger icon when navigation is visible */
    .nav-visible .hamburger-menu .menu-icon {
        display: none;
    }

    .floater{
        width: 90%;
    }


}